<template>
  <div id="OpenPositionApplyContainer" v-if="item">
    <div class="item-content white-content">
      <div class="is-sent" v-if="isFormSent && !isFormSentError">
        <icon-approve></icon-approve>
        <h2 class="h3">All done!</h2>
        <p>Your application has been successfully submitted!</p>
      </div>

      <div class="is-sent" v-else-if="isFormSent && isFormSentError">
        <icon-times></icon-times>
        <h2 class="h3">System error!</h2>
        <p>
          Please try again later or contact us
          <a href="mailto:hello@engenious.pl">hello@engenious.pl</a> or call
          <a href="tel:+48516156902">+48 516 156 902</a>
        </p>
      </div>

      <div v-else>
        <h2 class="h3">Apply to this role</h2>

        <div class="field">
          <label class="label">Name <span class="is-danger">*</span></label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': v$.form.name.$error }"
              type="text"
              v-model="v$.form.name.$model"
              placeholder="e.g. John"
            />
          </div>
          <p class="help is-danger" v-if="v$.form.name.$error">
            This field is required.
          </p>
        </div>

        <div class="field">
          <label class="label">Email <span class="is-danger">*</span></label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': v$.form.email.$error }"
              type="email"
              v-model="v$.form.email.$model"
              placeholder="e.g. john.smith@company.com"
            />
          </div>
          <p class="help is-danger" v-if="v$.form.email.$error">
            This email is invalid
          </p>
        </div>

        <div class="field">
          <label class="label"
            >Phone number <span class="is-danger">*</span></label
          >
          <div class="control">
            <input
              class="input"
              v-model="v$.form.phone.$model"
              placeholder="e.g. +44 123 456 789"
            />
          </div>
          <p class="help is-danger" v-if="v$.form.phone.$error">
            This email is invalid
          </p>
        </div>

        <div class="field">
          <label class="label"
            >CV / Resume: <span class="is-danger">*</span></label
          >
          <div class="hint">* We accept PDF, DOC, DOCX, JPG and PNG files</div>
          <div class="control">
            <div v-if="form.selectedFile" class="choosed-file">
              <icon-file></icon-file>
              <span class="name">{{ form.selectedFile.name }}</span>
              <a @click="unsetFile()">
                <icon-times></icon-times>
              </a>
            </div>
            <div v-else class="btn btn--primary upload-button">
              Upload
              <icon-download></icon-download>
              <input
                type="file"
                id="file"
                ref="file"
                accept="image/*,application/pdf,application/msword,.docx,.doc"
                v-on:change="handleFileUpload($event)"
              />
            </div>
          </div>
          <p class="help is-danger" v-if="v$.form.selectedFile.$error">
            This field is required and should be in an accepted format.
          </p>
        </div>

        <div class="c-form c-form-control c-form-acceptance">
          <div class="c-form-list-item">
            <label>
              <input
                type="checkbox"
                v-model="v$.form.agree1.$model"
                value="1"
              />
              <span class="c-form-list-item-label">
                {{ applyPage.attributes.agree1.description }}&nbsp;<span
                  class="is-danger"
                  >*</span
                >
                <br />
                <br />
                <span
                  class="content ql-editor"
                  v-html="applyPage.texts.agree1.description"
                ></span>
                <span class="help is-danger" v-if="v$.form.agree1.$error">
                  This field is required.
                </span>
              </span>
            </label>
          </div>
        </div>

        <div class="c-form c-form-control c-form-acceptance">
          <div class="c-form-list-item">
            <label>
              <input
                type="checkbox"
                v-model="v$.form.agree2.$model"
                value="1"
              />
              <span class="c-form-list-item-label">
                {{ applyPage.attributes.agree2.description }}&nbsp;<span
                  class="is-danger"
                  >*</span
                >
                <br />
                <br />
                <span
                  class="content ql-editor"
                  v-html="applyPage.texts.agree2.description"
                ></span>
                <span class="help is-danger" v-if="v$.form.agree2.$error">
                  This field is required.
                </span>
              </span>
            </label>
          </div>
        </div>

        <div class="info">
          <span class="is-danger">*</span> The fields marked with an asterisk
          are required
        </div>

        <div class="field is-grouped">
          <div class="control">
            <p class="help is-danger" v-if="isFormSentError">
              The message could not be sent. System error. Please try again
              later or <a href="/contact-us">contact us</a>
            </p>
            <button
              class="btn btn--primary send-btn"
              :disabled="v$.form.$invalid || isFormSending"
              @click="submitFile()"
            >
              <span v-if="isFormSending">Sending...</span>
              <span v-else> Apply now </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { paginatorService } from "@/services/paginator.service";
import { contentsService } from "@/services/contents.service";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import IconFile from "../../icons/icon-file";
import IconTimes from "../../icons/icon-times";
import IconDownload from "../../icons/icon-download";
import IconApprove from "@/icons/icon-approve.vue";

export default defineComponent({
  name: "OpenPositionApply",
  components: {
    IconApprove,
    IconDownload,
    IconTimes,
    IconFile,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      slug: "",
      page: undefined,
      applyPage: undefined,
      item: undefined,

      isFormSent: false,
      isFormSentError: false,
      isFormSending: false,

      form: {
        email: "",
        phone: "",
        name: "",
        selectedFile: null,
        agree1: null,
        agree2: null,
      },
    };
  },
  validations() {
    const supportedExt = ["doc", "docx", "pdf", "jpg", "jpeg", "png"];
    return {
      form: {
        name: { required },
        phone: { required },
        email: { required, email },
        selectedFile: {
          required,
          checked: (value) => {
            const p = (value.name ?? "").split(".");
            const ext = p[p.length - 1].toLowerCase();
            return supportedExt.includes(ext);
          },
        },
        agree1: { checked: (value) => value === true },
        agree2: { checked: (value) => value === true },
      },
    };
  },
  mounted() {
    if (this.$route.params.slug) {
      this.slug = this.$route.params.slug;
      this.loadContent(this.$route.params.slug);
    }
  },
  methods: {
    loadContent(slug) {
      contentsService.findBySlug("join-us").then((page) => {
        this.page = page;
      });
      contentsService.findBySlug("join-us-form").then((page) => {
        this.applyPage = page;
      });
      paginatorService.findBySlug("open-positions", slug).then((item) => {
        this.item = item;
      });
    },

    submitFile() {
      if (this.item && !this.v$.form.$invalid) {
        let formData = new FormData();
        formData.append("candidate[email]", this.form.email);
        formData.append("candidate[phone]", this.form.phone);
        formData.append("candidate[name]", this.form.name);
        formData.append("candidate[cv]", this.form.selectedFile);
        formData.append(
          "candidate[open_question_answers_attributes][0][open_question_id]",
          "1176799"
        );
        formData.append(
          "candidate[open_question_answers_attributes][0][content]",
          this.item.title
        );
        formData.append(
          "candidate[open_question_answers_attributes][1][open_question_id]",
          "1176800"
        );
        formData.append(
          "candidate[open_question_answers_attributes][1][content]",
          window.location.href
        );

        this.isFormSending = true;
        fetch(
          `https://engenious.recruitee.com/api/offers/${this.applyPage?.subtitle}/candidates`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((ret) => {
            if (ret.status >= 300) {
              this.isFormSent = true;
              this.isFormSentError = true;
            } else {
              this.isFormSent = true;
            }
            this.scrollTop();
          })
          .catch(() => {
            this.isFormSent = true;
            this.isFormSentError = true;
            this.scrollTop();
          });
      }
    },

    scrollTop() {
      const element = document.getElementById("OpenPositionApplyContainer");
      const rec = element.getBoundingClientRect();
      const pos = rec.top + window.scrollY - 100;
      window.scrollTo(0, pos);
    },

    /*
      Handles a change on the file upload
    */
    handleFileUpload(event) {
      this.v$.form.selectedFile.$model = event.target.files[0];
    },
    unsetFile() {
      this.v$.form.selectedFile.$model = null;
    },
  },
  watch: {
    slug(slug) {
      if (slug) {
        this.loadContent(slug);
      } else {
        this.item = undefined;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.h3 {
  font-weight: 600;
}

#OpenPositionApplyContainer {
  position: sticky;
  top: var(--header-height);
}

.item-content {
  background: var(--light-gray);
  border-radius: 30px;
  padding: rem(40px);
  @include mq-max(sm) {
    margin-left: calc(var(--container-px) / -2);
    margin-right: calc(var(--container-px) / -2);
    padding-left: calc(var(--container-px) / 2);
    padding-right: calc(var(--container-px) / 2);
    margin-bottom: rem(40px);
  }
}

.send-btn {
  @include mq-max(sm) {
    width: 100%;
  }
}

.choosed-file {
  display: flex;

  span {
    margin: 0 10px;
  }
}

.upload-button {
  position: relative;
  margin: 0.5rem 0;

  @include mq-max(sm) {
    width: 100%;
  }

  justify-content: center;
  display: inline-flex;
  align-items: center;
  gap: 1rem;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.send-btn {
  margin-top: 1rem;
}

.is-sent {
  background: var(--light-gray);
  border-radius: 30px;
  text-align: center;

  :deep svg {
    width: rem(48px);
    height: rem(48px);
  }

  p {
    margin-bottom: 0;
  }
}
</style>
